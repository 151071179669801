(function ($) {
    "use strict";

    const $switchers = $("#theme-switcher,#theme-switcher-mobile");
    const $body = $("body");
    const currentTheme = (localStorage.getItem("theme") ? localStorage.getItem("theme") : null);

    function switchTheme(e) {
        if (e.target.checked) {
            $body.removeClass("dark-mode").addClass("light-mode");
            localStorage.setItem("theme", "light");
        } else {
            $body.removeClass("light-mode").addClass("dark-mode");
            localStorage.setItem("theme", "dark");
        }
    }

    $switchers.on("change", switchTheme);

    if (currentTheme) {
        $body.addClass(currentTheme.concat("-mode"));

        if (currentTheme === "light") {
            $switchers.attr("checked", true);
        }
    }
}(jQuery));
